import { bool, func, string } from "prop-types";

import classes from "./MenuButton.module.scss";

const bars = [1, 2, 3, 4];

const MenuButton = ({ open = false, toggle, title = "Menu" }) => (
  <button
    onClick={toggle}
    className={classes.menu}
    title={title}
    aria-expanded={open}
  >
    {bars.map((number) => (
      <span
        key={`bar-${number}`}
        className={classes[`bar_${number}${open ? "_open" : ""}`]}
      />
    ))}
  </button>
);

MenuButton.propTypes = {
  open: bool,
  toggle: func,
  title: string,
};

export default MenuButton;
