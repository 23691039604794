import Image from "next/image";
import { useTranslation } from "next-i18next";
import { bool, number, object, oneOf, string } from "prop-types";

import classes from "./ImageComponent.module.scss";

const ImageComponent = ({
  layout,
  src,
  alt,
  width,
  height,
  sizes,
  priority,
  author,
  copyright,
  hideAuthor,
  title,
}) => {
  const { t } = useTranslation();

  switch (layout) {
    case "fill":
      return (
        <>
          <div
            className={classes.feedfactory_container}
            style={{
              height: `${height}px`,
            }}
          >
            <Image
              src={src}
              alt={alt ?? title ?? undefined}
              sizes={sizes}
              layout="fill"
              priority={priority}
            />
          </div>
          {copyright && !hideAuthor && (
            <div className={classes.author}>
              {t("imageFrom", { copyright })}
            </div>
          )}
        </>
      );
    default:
      return (
        <>
          <div className={classes.prepr_container}>
            <Image
              src={src}
              alt={alt}
              width={width}
              height={height}
              priority={priority}
            />
          </div>
          {author && !hideAuthor && (
            <div className={classes.author}>
              {t("imageFrom", { copyright: author })}
            </div>
          )}
        </>
      );
  }
};

ImageComponent.propTypes = {
  layout: oneOf(["fill", "fixed", "intrinsic", "responsive"]),
  src: string.isRequired,
  alt: string,
  width: number.isRequired,
  height: number.isRequired,
  sizes: string,
  author: string,
  copyright: string,
  priority: bool,
  hideAuthor: bool,
  title: string,
  style: object,
};

export default ImageComponent;
